
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { emptyToLine, getTestPort } from "@/utils/common";
import { Pageniation, Loading } from "@/utils/decorator";
import { formDownload } from "@/utils/tools";
import t from "@common/src/i18n/t";
import dayjs from "dayjs";
const management = namespace("management");
const base = namespace("base");
const app = namespace("app");
@Component({
  components: { TableEnableFlag }
})
@Pageniation("loadListData")
export default class TenantList extends Vue {
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @management.Action queryApplyListV2;
  @management.Action forbidTenant;
  @management.Action enableTenant;
  @management.Action updateTmallCheck;
  @management.Action updateSignupStatus;
  @management.Action queryViewContractUrlV2;
  @management.Action applyResign;
  @management.Action queyrStoreContactList;
  @management.Action insertRenewSend;
  @management.Action queryPrevRenewFailureList;
  @management.Action queryContractTmepList;
  @management.Action insertTmallUrge;
  @management.Action cancelSign;
  @base.Action getDictionaryList;
  showTmallCheck: boolean = false;
  showRevokeSign: boolean = false;
  showViewContactModal: boolean = false;
  showRenewSendModal: boolean = false;
  showPrevFailRenew: boolean = false; // 上次续约失败记录
  checkValue: string = "1";
  noPassReason: any = t("v210831.repeat-store");
  contractTemplateName: string = "";
  dayjs = dayjs;
  searchForm = {
    shopK2Name: "",
    contactsName: "",
    contactsPhone: "",
    tmallApplyStatus: "",
    startApplySubmitTime: "",
    endApplySubmitTime: "",
    startAuditTime: "",
    endAuditTime: "",
    startTmallVerifyTime: "",
    endTmallVerifyTime: "",
    startOpenTime: "",
    endOpenTime: ""
  };
  revokeSignReason: string = ""; // 撤销签约原因
  emptyToLine = emptyToLine;
  tenantList: any[] = [];
  applyStatusList: any[] = [];
  signupList: any = [];
  tmallVerifyStatusList: any = [];
  get searchList() {
    return [
      {
        label: t("v210831.store-name"),
        type: "input",
        value: "",
        prop: "shopK2Name"
      },
      {
        label: t("v210831.person-in-charge"),
        type: "input",
        value: "",
        prop: "contactsName"
      },
      {
        label: t("v210831.telephone-number-of"),
        type: "input",
        value: "",
        prop: "contactsPhone"
      },
      {
        label: t("v210831.application-status"),
        type: "select",
        selectList: this.applyStatusList,
        value: "",
        prop: "tmallApplyStatus"
      },
      {
        label: t("v210831.application-submission-time"),
        type: "timerange",
        value: "",
        prop: "applySubmitTime"
      },
      {
        label: t("v210831.audit-time-of-jiatong"),
        type: "timerange",
        value: "",
        prop: "auditTime"
      },
      {
        label: t("v210831.tmall-review-time"),
        type: "timerange",
        value: "",
        prop: "tmallVerifyTime"
      },
      {
        label: t("v210831.opening-time-not-flag"),
        type: "timerange",
        value: "",
        prop: "openTime"
      }
    ];
  }
  contractTempList: any = [];
  handleRenewModal() {
    this.contractTemplateName = "";
    this.showRenewSendModal = true;
    this.queryContractTmepList().then(data => {
      this.contractTempList = data.data.list || [];
    });
  }
  renewFailureList: any = [];
  handleShowPrevFailRenew() {
    this.showPrevFailRenew = true;
    this.queryPrevRenewFailureList().then(data => {
      this.renewFailureList = data.data.list;
    });
  }
  activeRow: any = "";
  handleTmallCheck(row) {
    this.activeRow = row;
    this.checkValue = "1";
    this.noPassReason = t("v210831.repeat-store");
    this.showTmallCheck = true;
  }
  handleCheckResultConfirm() {
    this.btnStartLoading();
    this.updateTmallCheck({
      applyCode: this.activeRow.applyCode,
      tmallVerifyResult: this.checkValue,
      tmallVerifyMessage: this.noPassReason
    })
      .then(data => {
        this.$message.success(t("setting.operate-successfully"));
        this.showTmallCheck = false;
        this.init();
      })
      .finally(() => {
        this.btnStopLoading();
      });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    console.log(data);
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "applySubmitTime") {
          this.searchForm.startApplySubmitTime = item.value ? item.value[0] : "";
          this.searchForm.endApplySubmitTime = item.value ? item.value[1] : "";
        } else if (item.prop === "auditTime") {
          this.searchForm.startAuditTime = item.value ? item.value[0] : "";
          this.searchForm.endAuditTime = item.value ? item.value[1] : "";
        } else if (item.prop === "tmallVerifyTime") {
          this.searchForm.startTmallVerifyTime = item.value ? item.value[0] : "";
          this.searchForm.endTmallVerifyTime = item.value ? item.value[1] : "";
        } else if (item.prop === "openTime") {
          this.searchForm.startOpenTime = item.value ? item.value[0] : "";
          this.searchForm.endOpenTime = item.value ? item.value[1] : "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    this.loadListData();
  }
  handleSendRenew() {
    if (!this.contractTemplateName) {
      return this.$message.error(t("v210831.please-select-a-renewal-contract"));
    }
    this.btnStartLoading();
    this.insertRenewSend({
      contractName: this.contractTemplateName
    })
      .then(data => {
        const totalNum = data.data;
        if (totalNum === 0) {
          this.$message.warning(t("v210831.there-are-currently-not-condition-store"));
        } else {
          this.$alert(`已执行，共有${totalNum}家门店符合条件，续约下发将在24小时内完成。`, t("v210831.tips"), {
            confirmButtonText: t("v210831.determine")
          });
        }
        this.showRenewSendModal = false;
      })
      .finally(() => {
        this.btnStopLoading();
      });
  }
  curContractList: any = [];
  handleShowContact(row) {
    this.activeRow = row;
    this.showViewContactModal = true;
    this.queyrStoreContactList({
      k2Number: row.k2Number
    }).then(data => {
      this.curContractList = data.data.list || [];
    });
  }
  handleViewContract(fileSuffix) {
    this.queryViewContractUrlV2({
      contractFileUrl: fileSuffix
    }).then(data => {
      window.open(data.data);
    });
  }
  handleExportData() {
    formDownload(`${getTestPort()}/storeApply/export`, this.searchForm);
  }
  handleReSign(row) {
    this.btnStartLoading();
    this.applyResign({
      applyCode: this.activeRow.applyCode,
      esignFlowId: row.esignFlowId
    })
      .then(data => {
        this.$message.success(t("v210831.sent-to-sign"));
        this.handleShowContact(this.activeRow);
      })
      .finally(() => {
        this.btnStopLoading();
      });
  }
  handleCancelSign(row) {
    this.activeFlowId = row.esignFlowId;
    this.revokeSignReason = "";
    this.showRevokeSign = true;
  }
  handleUrge(row) {
    this.btnStartLoading();
    this.insertTmallUrge({
      applyCode: this.activeRow.applyCode,
      esignFlowId: row.esignFlowId
    })
      .then(data => {
        this.$message.success(t("v210831.signing-reminder-sent"));
      })
      .finally(() => {
        this.btnStopLoading();
      });
  }
  activeFlowId: string = "";
  handleRevokeSign() {
    this.btnStartLoading();
    this.cancelSign({
      applyCode: this.activeRow.applyCode,
      revokeReason: this.revokeSignReason,
      esignFlowId: this.activeFlowId
    })
      .then(data => {
        this.$message.success(t("v210831.the-contract-has-revocation"));
        this.showViewContactModal = false;
        this.showRevokeSign = false;
        this.init();
      })
      .finally(() => {
        this.btnStopLoading();
      });
  }
  created() {
    this.init();
  }
  @Loading()
  loadListData() {
    return this.queryApplyListV2({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.tenantList = data.data.list;
      return data;
    });
  }
  async init() {
    this.loadListData();
    // 审核状态字典
    this.getDictionaryList("tmall_apply_status").then(data => {
      this.applyStatusList = data;
    });
    this.getDictionaryList("signed_status").then(data => {
      this.signupList = data;
    });
    this.getDictionaryList("tmall_verify_result").then(data => {
      this.tmallVerifyStatusList = data;
    });
  }
  handleLinkOpen(row) {
    this.$router.push(`/management/store-open?code=${row.applyCode}`);
  }
  handleLinkView(row) {
    this.$router.push(`/management/apply-view/${row.applyCode}`);
  }
}
