import user from "../store/modules/user";

console.log(user.state.token);
export function formDownload(url, param) {
  let kyes = Object.keys(param);
  let paramArr = kyes.map(item => {
    return {
      name: item,
      value: param[item]
    };
  });
  paramArr.push({
    name: "userAuthToken",
    value: user.state.token
  });
  console.log(paramArr);
  var form = document.createElement("form");
  document.getElementsByTagName("body")[0].appendChild(form);
  form.setAttribute("style", "display:none");
  form.setAttribute("target", "_blank");
  form.setAttribute("method", "post");
  form.setAttribute("action", url);
  paramArr.forEach(item => {
    var input1 = document.createElement("input");
    input1.setAttribute("type", "hidden");
    input1.setAttribute("name", item.name);
    input1.setAttribute("value", item.value);
    form.appendChild(input1);
  });
  form.submit();
  form.remove();
}
